import React, { Component } from 'react';
import { getCrewmembers, addCrewmember, getCrewmember, uploadImage, getCrewSchedules } from '../../../../shared/Api';
import { resetFormValues, touchField, updateFormOnChange, getSubmitClass, fieldsToObject } from '../../../../shared/FormHelper';
import { AuthContext } from "../../../../shared/Auth";
import BasicForm from '../../../../components/Form/BasicForm';
import Loader from '../../../../components/Layout/Loader/Loader';
import { Link } from 'react-router-dom';
import { getMessages } from '../../../../components/Messages/GetMessages';

class CrewmembersStep extends Component {
    static contextType = AuthContext;

    state = {
        loading: false,
        savingCrewmember: false,
        equipment: [],
        addEquipmentShow: false,
        search: '',
        selectedEquipment: this.props.obj.crew ? this.props.obj.crew : [],
        newEquipmentForm: {
            name: 'add-new-crewmember',
            isValid: false,
            submitErrorMessage: null,
            loading: false,
            fields: [
                {
                    name: 'firstName',
                    value: '',
                    defaultValue: '',
                    type: 'text',
                    label: 'First name',
                    placeholder: 'First name',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: null,
                    required: true,
                    validators: [
                        {
                            type: 'required',
                            message: getMessages('firstname_required')
                        }
                    ]
                },
                {
                    name: 'lastName',
                    value: '',
                    defaultValue: '',
                    type: 'text',
                    label: 'Last name',
                    placeholder: 'Last name',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: null,
                    required: true,
                    validators: [
                        {
                            type: 'required',
                            message: getMessages('lastname_required')
                        }
                    ]
                },
                {
                    name: 'email',
                    value: '',
                    defaultValue: '',
                    type: 'text',
                    label: 'Email',
                    placeholder: 'Email',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: null,
                    required: true,
                    validators: [
                        {
                            type: 'required',
                            message: getMessages('email_required')
                        },
                        {
                            type: 'email',
                            message: getMessages('invalid_email')
                        }
                    ]
                },
                {
                    name: 'phoneNumber',
                    value: '',
                    defaultValue: '',
                    type: 'text',
                    subtype: 'phone',
                    label: 'Phone number',
                    placeholder: 'Phone number',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: null,
                    required: true,
                    validators: [
                        {
                            type: 'required',
                            message: getMessages('phone_required')
                        }
                    ]
                },
                {
                    name: 'skills',
                    value: '',
                    defaultValue: '',
                    type: 'textarea',
                    label: 'Skills',
                    placeholder: 'Skills',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: null,
                    required: false,
                    rows: 4
                },
                {
                    name: 'image',
                    value: '',
                    defaultValue: '',
                    type: 'dropzone',
                    label: 'Crewmember Photo',
                    placeholder: 'Crewmember Photo',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: null,
                    required: false,
                    localSrc: '',
                    onDrop: (acceptedFiles) => { this.onImageDrop(acceptedFiles); },
                    onRemove: () => { this.onImageRemove(); }
                }
            ],
            buttons: [
                {
                    title: 'Save Crewmember',
                    submit: true,
                    className: 'btn btn-primary font-weight-bolder text-uppercase px-9 py-4 mr-2 ',
                    onClick: () => this.addEquipment(),
                    disabled: () => false
                },
                {
                    title: 'Cancel',
                    className: 'btn btn-light-secondary font-weight-bolder text-uppercase px-9 py-4',
                    onClick: () => this.closeAddNewEquipmentForm(),
                    disabled: () => false
                }
            ]
        }
    };

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        
        this.props.setStep('crewmembers');
        await this.loadEquipment();
        await this.loadSelectedCrewmembers();
        
        if(this.props.isTutorial && this.props.tutorialStep === 'addingJob') {
            window.$('[id="job-crewmembers-title"]').tooltip({ trigger: 'manual' });
            window.$('[id="job-crewmembers-title"]').tooltip('hide');
            window.$('[id="job-crewmembers-title"]').tooltip('show');
            document.getElementById("skip-tutorial-button").style.display = "block";
        }
    }

    componentWillUnmount() {
        if(this.props.isTutorial && this.props.tutorialStep === 'addingJob') {
            window.$('[id="job-crewmembers-title"]').tooltip('hide');
            document.getElementById("skip-tutorial-button").style.display = "none";
        }
    }

    loadEquipment = async () => {
        this.setState({ loading: true });
        const currentUser = this.context.currentUser;
        const companyId = currentUser.userProfile.companyID;
        const inventoryId = currentUser.company.inventoryID;

        const crewMembers = await getCrewmembers(inventoryId, companyId, true);
        const reservations = await getCrewSchedules();

        crewMembers.data.forEach(x => {
            x.available = !reservations.data.map(x => x.crewMemberID).includes(x.id);
        });

        crewMembers.data.sort((a, b) => {
            if (a.firstName.toLowerCase().trim() < b.firstName.toLowerCase().trim()) {
              return -1;
            }
            if (a.firstName.toLowerCase().trim() > b.firstName.toLowerCase().trim()) {
              return 1;
            }
            return 0;
          })

          console.log(crewMembers.data)


        this.setState({ equipment: crewMembers.data, loading: false });
    }

    loadSelectedCrewmembers = async () => {
        this.setState({ selectedEquipment: this.props.obj.crew ? this.props.obj.crew : [] });
    }

    getReservations = async () => {
        const reservations = await getCrewSchedules();

        const notAvailable = reservations.filter(x => this.isBetweenDates(x.startDate, this.props.obj.job.startDate, this.props.obj.job.endDate)
            || this.isBetweenDates(x.endDate, this.props.obj.job.startDate, this.props.obj.job.endDate));

        return notAvailable;
    }

    isBetweenDates = (check, from, to) => {
        return check.getTime() <= to.getTime() && check.getTime() >= from.getTime();
    }

    onImageDrop = (acceptedFiles) => {
        let form = { ...this.state.newEquipmentForm };
        let imageField = form.fields.find(x => x.name === 'image');

        const loc = window.URL.createObjectURL(acceptedFiles[0]);

        imageField.value = acceptedFiles[0];
        imageField.localSrc = loc;
        this.setState({ newEquipmentForm: form });
    }

    selectEquipment = (equipmentID) => {
        let selectedEquipment = [...this.state.selectedEquipment];
        var index = selectedEquipment.indexOf(equipmentID);
        if (index !== -1) {
            selectedEquipment.splice(index, 1);
        } else {
            selectedEquipment.push(equipmentID);
        }

        this.setState({ selectedEquipment: selectedEquipment });
    }

    onImageRemove = () => {
        let form = { ...this.state.newEquipmentForm };
        let imageField = form.fields.find(x => x.name === 'image');

        imageField.value = '';
        imageField.localSrc = '';
        this.setState({ newEquipmentForm: form });
    }

    addEquipment = async () => {
        this.setState({savingCrewmember: true})
        const currentUser = this.context.currentUser;
        const inventoryId = currentUser.company.inventoryID;
        const companyId = currentUser.userProfile.companyID;
        const equipment = fieldsToObject(this.state.newEquipmentForm.fields);

        const imageField = this.state.newEquipmentForm.fields.find(x => x.name === 'image');
        if (imageField.value) {
            equipment.imageTimeStamp = new Date();
            equipment.hasProfileImage = true;
        }

        const eq = await addCrewmember(inventoryId, companyId, equipment);

        if (imageField.value) {
            const uploadedEquipment = await getCrewmember(inventoryId, eq.data);
            await uploadImage(companyId, 'crewMember', eq.data, uploadedEquipment.data.imageTimeStamp, imageField.value)
        }

        this.closeAddNewEquipmentForm();
        this.setState({savingCrewmember: false});
        this.loadEquipment();
        
    }

    next = async () => {
        await this.props.setCrewMembers(this.state.selectedEquipment);
        this.props.history.push('/jobs/new/folders');
    }

    closeAddNewEquipmentForm = () => {
        let updatedForm = { ...this.state.newEquipmentForm };
        resetFormValues(updatedForm);
        updatedForm.isValid = false;
        updatedForm.submitErrorMessage = '';
        updatedForm.loading = false;

        this.setState({
            newEquipmentForm: updatedForm,
            addEquipmentShow: false
        });
    }

    prepareAddEquipmentForm = () => {
        let form = this.state.newEquipmentForm;

        form.handleInputChange = (event, obj) => {
            const updatedForm = updateFormOnChange(event, obj, this.state.newEquipmentForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        form.touchField = (event) => {
            const updatedForm = touchField(event, this.state.newEquipmentForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        return form;
    }

    getClass = (className) => {
        if(this.props.saving)
            className += " spinner spinner-white spinner-right";

        return className;
    }

    equipment = () => {
        if (!this.state.search)
            return this.state.equipment;

        return this.state.equipment.filter(x => (x.firstName + ' ' + x.lastName).toLowerCase().includes(this.state.search.toLowerCase()));
    }

    render() {
        const addEquipmentForm = this.prepareAddEquipmentForm();
        const equipment = this.equipment();

        return (
            <>
                <a id="job-crewmembers-title" data-toggle="tooltip" title="Any other Crewmembers need to be scheduled to the job? Select them here if they’re available."
                    data-placement="left">
                    <h3 className="mb-10 font-weight-bold text-dark">Crewmembers</h3>
                </a>

                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        onChange={(e) => { this.setState({ search: e.target.value }); }}
                        placeholder="Search Crewmember" />
                </div>

                <button onClick={() => this.setState({ addEquipmentShow: true })}
                    className="btn btn-block btn-light-primary font-weight-bold mb-8">
                    <i className="ki ki-plus icon-md mr-2" />Add New Crewmember</button>

                {this.state.addEquipmentShow &&
                    <div className="mb-20">
                        <BasicForm {...addEquipmentForm} saving={this.state.savingCrewmember}/>
                    </div>
                }

                {this.state.loading
                    ?
                    <Loader height="100px" />
                    :
                    <div>
                        {equipment.length > 0 ?
                            <div className='navbar-nav-scroll'>
                            {equipment.map((equipment,index) => (
                                    <div key={"crw-mbrs-stp"+index} className="d-flex align-items-center mb-10">
                                        <div className="d-flex align-items-center">

                                            <div className="symbol symbol-100 symbol-sm flex-shrink-0">
                                                {equipment.image ?
                                                    <img className="" src={equipment.image} alt="photo" />
                                                    : <span className="symbol-label"></span>}
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column flex-grow-1 font-weight-bold ml-5">
                                            <a className="text-dark text-hover-primary mb-1 font-size-lg">
                                                {equipment.firstName + " " + equipment.lastName}
                                            </a>
                                            <span className="text-muted">{equipment.email}</span>
                                            <span className="text-muted">{!this.props.unavailableCrewmembers.includes(equipment.id) ? "Available" : "Not Available"}</span>
                                        </div>
                                        <label className="checkbox checkbox-lg checkbox-light-primary checkbox-inline flex-shrink-0 m-0 mx-4">
                                            <input disabled={this.props.unavailableCrewmembers.includes(equipment.id)}
                                                onChange={() => this.selectEquipment(equipment.id)}
                                                checked={this.state.selectedEquipment.includes(equipment.id) && !this.props.unavailableCrewmembers.includes(equipment.id)}
                                                type="checkbox" defaultValue={1} />
                                            <span />
                                        </label>
                                    </div>
                                ))}
                            </div>
                            :
                            <div className="d-flex align-items-center mb-10">
                                <div className="d-flex flex-column flex-grow-1 font-weight-bold">
                                    <i className="flaticon2-group step-graphic" />
                                    <span className="font-italic">You don't have any crewmembers added to the job at the moment. Click the button above to start adding them.</span>
                                </div>
                            </div>
                            }
                        <div className="justify-content-between border-top mt-5 pt-10">
                            <button className={this.getClass("btn btn-primary font-weight-bolder text-uppercase px-9 py-4 float-right ml-2")}
                                onClick={this.next}>Next</button>
                            <button className={this.getClass("btn btn-primary font-weight-bolder text-uppercase px-9 py-4 float-right ml-2")}
                                onClick={this.props.save}
                            >Skip and Save</button>
                            <Link className="btn btn-light-secondary font-weight-bolder text-uppercase px-9 py-4 float-right ml-2"
                                to='/jobs/new/materials'>Back</Link>
                        </div>
                    </div>
                }
            </>
        );
    }
}

export default CrewmembersStep;