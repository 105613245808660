import { Timestamp } from "firebase/firestore";
import { storage } from "../../firebase";

export const uploadImage = async (
    companyId:string,
    collection:string,
    docId:string,
    timestamp:Timestamp,
    imageValue:any
  ) => {
    try {
      const storageRef = storage.ref(
        companyId + "/" + collection + "/" + docId + "/" + timestamp.seconds + "/med"
      );
      await storageRef.put(imageValue);
    } catch (exception) {
      console.log(exception);
    }
  };

  export const uploadFile = async (
    companyId:string,
    collection:string,
    docId:string,
    timestamp:Timestamp,
    fileValue:File
  ) => {
    try{
        var fileName = "med"

        if(!isImageFile(fileValue.name)){
            fileName = fileValue.name
        }
      const storageRef = storage.ref(
        companyId + "/" + collection + "/" + docId + "/" + timestamp.seconds + "/" + fileName
      );
      const result = await storageRef.put(fileValue)
      return result.state
    }catch(error:any){
      console.log(error)
      return "error"
    }
  };

  export const isImageFile = (fileName:string) =>{
    const imageExtensionRegex = /\.(jpg|jpeg|png|gif|bmp|svg)$/i;
    return imageExtensionRegex.test(fileName)
  }