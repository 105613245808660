import React, { Component } from 'react';
import SimpleForm from '../../components/Form/SimpleForm';
import { validateField } from '../../shared/Validation';
import Loader from '../../components/Layout/Loader/Loader';

import { AuthContext } from "../../shared/Auth";
import { getMessages } from '../../components/Messages/GetMessages';
import { getClient, updateClient } from '../../shared/Api';
import { normalizeInput, objectToFields } from '../../shared/FormHelper';

class ClientEdit extends Component {
  static contextType = AuthContext;

  state = {
    client: null,
    fields: [
      {
        name: 'companyName',
        value: '',
        defaultValue: '',
        type: 'text',
        label: 'New Company Name',
        placeholder: 'New Company Name',
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: getMessages('enter_company'),
        required: true,
        validators: [
          {
            type: 'required',
            message: getMessages('company_required')
          }
        ]
      },
      {
        name: 'phoneNumber',
        value: '',
        defaultValue: '',
        placeholder: 'Contact Phone',
        type: 'text',
        subtype: 'phone',
        label: 'Contact Phone',
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: getMessages('enter_client_phone'),
        required: true,
        validators: [
          {
            type: 'required',
            message: getMessages('client_phone_required')
          },
          {
            type: 'phoneNumber',
            message: getMessages('invalid_phone')
          }
        ]
      },
      {
        name: 'address',
        value: '',
        defaultValue: '',
        placeholder: 'Address',
        type: 'text',
        label:'Address',
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: getMessages('enter_client_address'),
        required: false,
      },
      {
        name: "notes",
        value: "",
        defaultValue: "",
        placeholder: "Notes",
        type: "text",
        label: "Notes",
        isValid: true,
        validationMessage: "",
        isTouched: false,
        additionalMessage: getMessages("enter_client_notes"),
        required: false,
      }
    ],
    isValid: true,
    submitError: false,
    submitErrorMessage: '',
    loading: false
  };

  componentDidMount() {
    this.loadClient();
  }

  loadClient = async () => {
    const id = this.props.match.params.id;
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    const clientResult = await getClient(inventoryId, id);

    const stateFields = [...this.state.fields];
    const fields = objectToFields(stateFields, clientResult.data);

    this.setState({ client: clientResult.data, fields: fields });
  }

  validate = () => {
    this.state.fields.forEach(field => {
      validateField(field);
    });
  }

  clearValues() {
    let updatedFields = [...this.state.fields];
    updatedFields.forEach(element => {
      element.value = element.defaultValue;
    });

    this.setState({ fields: updatedFields });
  }

  getGlobalValid = (fields) => {
    let isValid = true;
    fields.forEach(element => {
      if ((element.required && !element.value) || !element.isValid) isValid = false;
    });

    return isValid;
  }

  touchField = (field) => {
    let fields = this.state.fields;
    let updatedField = fields.find(x => x.name === field.name);
    if (!updatedField)
      return;

    updatedField.isTouched = field.value !== field.defaultValue;
    this.setState({ fields: fields });
  }

  addValidationClass(field, cl) {
    if (field.isValid && field.value) return cl + ' is-valid';
    if (!field.isValid && field.value) return cl + ' is-invalid';

    return cl;
  }

  gnerateInputId(field) {
    return "ca-input-" + field.name;
  }

  handleChange = (event) => {
    let fields = [...this.state.fields];
    let field = fields.find(x => x.name === event.target.name);

    if (!field)
      return;

    if(field.subtype === 'phone') {
      field.value = normalizeInput(event.target.value, field.value);
    } else {
      field.value = event.target.value;
    }
    this.validate();

    if (!field.value)
      field.isTouched = false;

    const globalValid = this.getGlobalValid(fields);
    this.setState({ fields: fields, isValid: globalValid });
  }

  getObjectFromFields = () => {
    let obj = {};
    this.state.fields.forEach(item => {
      if (item.name !== 'image')
        obj[item.name] = item.value;
    });

    return obj;
  }

  handelSubmit = async (event) => {
    this.setState({ loading: true });
    event.preventDefault();
    const obj = this.getObjectFromFields();

    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    await updateClient(inventoryId, this.state.client.id, obj);
    this.props.history.goBack()
  }

  submitButton = () => {
    const submitBtnClasses = 'btn btn-primary mr-2';
    const submitBtnClassesWithError = this.state.submitError ? submitBtnClasses + ' is-invalid' : submitBtnClasses;
    const submitBtnClassesFinal = this.state.loading ? submitBtnClassesWithError + ' spinner spinner-white spinner-right' : submitBtnClassesWithError;

    return {
      title: "Save",
      className: submitBtnClassesFinal,
      onClick: this.handelSubmit,
      disabled: !this.state.isValid || this.state.loading
    };
  }

  cancelButton = () => {
    return {
      title: "Cancel",
      className: "btn btn-secondary mr-2",
      onClick: () => { this.props.history.goBack() }
    };
  }

  render() {
    if (!this.state.client) {
      return <Loader />
    }
    return (
      <React.Fragment>
        <div className="card card-custom">
          <div className="card-header">
            <h3 className="card-title">
              Edit client
            </h3>
            <div className="card-toolbar">
              <div className="example-tools justify-content-center">
                <span className="example-toggle" data-toggle="tooltip" title="View code" />
                <span className="example-copy" data-toggle="tooltip" title="Copy code" />
              </div>
            </div>
          </div>
          <SimpleForm
            formUniqueName="edit-client"
            fields={this.state.fields}
            touchField={this.touchField}
            submitButton={this.submitButton()}
            cancelButton={this.cancelButton()}
            handleInputChange={this.handleChange}
            onSubmit={this.handelSubmit}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default ClientEdit;