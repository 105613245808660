import { Timestamp } from "firebase/firestore"
import { TaskProductEntity } from "../taskmanagement/Task"

export class BoreEntity{
    id?: string | null = null
    boreName?: string | null = null
    startDate?: Timestamp | null = null
    endDate?: Timestamp | null = null
    boreDiameter?: number | null = null
    boreLength?: number | null = null
    boreNumber?: number | null = null
    boreProgress?: number | null = null
    crewID?: string | null = null
    endBoreLog?: string | null = null
    finished: boolean = false
    jobID?: string | null  = null
    address?: string | null = null
    lat?: number | null = null
    long?: number | null = null
    lng?: number | null = null
    notes?: string | null = null
    productPipe?: string | null = null
    soilConditions?: string | null = null
    startBoreLog?: string | null = null
    taskID?: string | null = null
    defaultDistance?: number | null = null
    products: TaskProductEntity[] | null = []
}